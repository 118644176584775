import React from "react";
import Seo from "../components/Seo";
import Youtube from "../components/Youtube";
import SignupForm from "../components/SignupForm";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";

// import useMediaQuery from "beautiful-react-hooks/useMediaQuery";

// import JoinBeta from "../components/JoinBeta";
// import SignupForm from "../components/SignupForm";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

const title = "Website Plagiarism Checker - PlagiaShield";
const description =
  "Check your entire site for plagiarism. Find all domains with content similar to yours. Get rid of content thefts.";

const IndexPage = ({ data }) => {
  const isLarge = true
  // useMediaQuery("(min-width: 48rem)");

  // const { image } = useQuery(graphql`
  //   query {
  //     fileName: file(relativePath: { eq: "images/jpg/plagiashield-result.png" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 400, maxHeight: 250) {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //   }
  // `);

  console.log("image: ", data);
  const Image = () => <Img fluid={data.filename.childImageSharp.fluid} alt="" />;

  const Video = () => {
    if (isLarge) {
      return <Youtube url="https://www.youtube.com/watch?v=sywk5GJyseg" />;
    } else {
      return <Youtube url="https://www.youtube.com/watch?v=QCxPKTMBk38" square={true} />;
    }
  };

  return (
    <React.Fragment>
      <div className="plagia-checker-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
        <h1 className="display-4">Website Plagiarism Checker</h1>
        <p className="lead mb-4">
          With PlagiaShield, find all domains with content similar to yours.
          <br />
          Get rid of thefts.
          <br />
          Rank better.
        </p>
      </div>
      <Container className="pb-4">
        {/* <div className="card-body "> */}
        <h2 className="text-center mb-4">Check your entire site for plagiarism</h2>
        <p className="text-center mb-2">
          In record time, find all content thefts.
          <br /> PlagiaShield also prepares everything so you can act on the results.
        </p>
        {/* </div> */}
        <SignupForm text="Get Started" />
      </Container>

      <Container>
        <h2 className="text-center mb-4">Fight plagiarism in 4 steps</h2>
        {/* <h2 className="text-center mb-4">Get results in minutes</h2> */}
        <Video />
        <Row className="mt-5">
          <div className="col-md-6">
            <h3>
              <span className="text-danger">Step 1.</span> Check your site for plagiarism
            </h3>
            <p>
              It is as simple as enter your domain URL in PlagiaShield.
              <br />
              You will get results in a few minutes.
            </p>
            <p>We scan all written content on your site and look for similar text online.</p>
          </div>
          <div className="col-md-6">
            <Image />
          </div>
        </Row>
        <SignupForm text="Check your site" />
      </Container>
      <Container className="pb-4">
        <h3 className="mt-4">
          <span className="text-danger">Step 2.</span> Quickly identify thefts
        </h3>
        <p>
          Handling plagiarism for hundreds or thousands of URLs can be overwhelming.
          <br />
          PlagiaShield makes your work more comfortable and more productive.
        </p>
        <p>
          All context is provided so you can quickly confirm if a website is stealing content from
          you:
        </p>
        <ul>
          <li>Statistics on the level of plagiarism</li>
          <li>Screenshots</li>
          <li>Detailed comparison between your texts and theirs</li>
        </ul>
        <h3 className="mt-5">
          <span className="text-danger">Step 3.</span> Ask owners for content removal
        </h3>
        <p>
          Now that you identified which sites are infringing copyrights, ask their owner to remove
          the paragraphs or the pages with problematic content.
        </p>
        <p>
          For each domain, PlagiaShield prepares the email for you to send. It contains everything
          you need: from serious warnings to the list of URLs copying your text.
        </p>
        <p>
          Most of the owners will act on your email. They will remove your texts from their
          paragraphs or delete the infringing page from their site.
        </p>
        <p></p>
        <h3 className="mt-5">
          <span className="text-danger">Step 4.</span> Fill in DMCAs
        </h3>
        <p>
          Thanks to the Digital Millenium Copyright Act (DMCA), you can go one step further and
          easily file complaints for <Link to="/what-is-copyright">copyright</Link> infringements.
          Within the Search Console, Google allows you to ask for the de-indexing of URLs copying
          your content.
        </p>
        <p>
          Filling this form is a tedious process when there dozens or hundreds of thefts for a page.
        </p>
        <p>
          PlagiaShield computes each field of the DMCA form for you. It also finds the best sentence
          to pick for your claims.
        </p>

        {/* <h2>Built for large websites</h2>
        <p>PlagiaShield is built for large sites and blogs with productivity as a priority.</p> */}
        {/* <h3>Built for large websites</h3> */}
        {/* <p className="lead text-center">Try the public Beta now.</p> */}
        {/* <SignupForm /> */}
      </Container>
      <Seo manualTitle={title} manualDescription={description} manualSlug="/website-plagiarism-checker" />
    </React.Fragment>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    filename: file(relativePath: { eq: "plagiashield-result.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
