import React, { useState } from "react";
// import { useStaticQuery, graphql, navigate } from "gatsby";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
// import useMediaQuery from "beautiful-react-hooks/useMediaQuery";
import useIsLarge from "../useIsLarge";

const SignupForm = ({ text }) => {
  const { values, handleChange, handleSubmit } = useForm(suscribe);
  const isLarge = useIsLarge()
  // const isLarge = useMediaQuery("(min-width: 48rem)");
  // const [show, setShow] = useState(false);
  const [alertParams, setAlertParams] = useState({
    show: false,
    text: "",
    variant: "",
    buttonVariant: "",
  });

  let link = `https://app.plagiashield.com/app/signup`;
  if (values.email !== undefined) {
    link = `https://app.plagiashield.com/app/signup?email=${values.email}`;
  }

  let buttonText = "Start Now";
  if (text) {
    buttonText = text;
  }

  function suscribe() {
    console.log(link);
    return;
  }

  const style = isLarge ? {
    minWidth: 50, maxWidth: '50%'
  } : {
    width: '100%'
  }

  const placeholder = isLarge ? 'Email address' : 'Email'


  return (
    <div className="signupdiv row justify-content-center">
      <Card className="mt-2" border="light" style={style}>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <InputGroup size="lg">
              <FormControl
                type="email"
                placeholder={placeholder}
                aria-label="email"
                aria-describedby="email"
                className="border-primary"
                id="email"
                onChange={handleChange}
              />
              {/* <InputGroup.Append> */}
              <Button href={link} type="submit" className="font-weight-bold">
                {buttonText}
              </Button>
              {/* </InputGroup.Append> */}
            </InputGroup>
          </Form>
        </Card.Body>
      </Card>
      <Alert show={alertParams.show} variant="light">
        <p>{alertParams.text}</p>
        <hr />
        <div className="d-flex justify-content-end">
          <Button
            onClick={() => setAlertParams({ show: false })}
            variant={alertParams.buttonVariant}
          >
            Close
          </Button>
        </div>
      </Alert>
      {/* <SuccessAlert /> */}
    </div>
  );
};

const useForm = (callback) => {
  const [values, setValues] = useState({});

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    callback();
  };

  const handleChange = (event) => {
    // console.log(event)
    event.persist();
    setValues((values) => ({ ...values, [event.target.id]: event.target.value }));
  };

  return {
    handleChange,
    handleSubmit,
    values,
  };
};

export default SignupForm;
