import { useState, useEffect } from "react";
import useWindowSize from "../useWindowSize/useWindowSize";

function useIsLarge() {
    const [isLarge, setIsLarge] = useState(true);
    const width = useWindowSize();

    useEffect(() => {
        console.log("width: ", width);
        if (width !== undefined && width < 992) {
            setIsLarge(false);
        } else {
            setIsLarge(true);
        }
    }, [width])

    return isLarge;
}

export default useIsLarge;